<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false">
      {{$t('withdraw.addNewWithdrawal')}}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary"><i class="las la-money-bill"></i>
              {{ $t('withdraw.withdrawableRevenue') }}
              </h5>
            </template>
            <p class="font-size-22 text-primary mb-0">{{storeActive.balance}} <span class="text-gray">
            {{$t('main.sar')}}
            </span></p>
          </b-card>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary"><i class="las la-file-alt"></i>
                {{$t('withdraw.Withdrawals')}}
              </h5>
            </template>
            <b-row>
              <b-col md="6" class="mb-3">
                <input-form :placeholder="$t('main.amount')" :label="$t('main.amount')" v-model.number="withdraw.amount" name="amount"  :validate="`required|numeric|max_value:${storeActive.balance}`"
                  type="number"
                ></input-form>
              </b-col>
            </b-row>
          </b-card>
        </div>

        <div class="form-actions">
          <b-button variant="outline-primary" @click="$router.back()">
            {{$t('main.back')}}
          </b-button>
          <b-button v-if="$route.name !== 'showProduct'" variant="primary" type="submit" :disabled="loadingSubmit">
            <span v-if="!loadingSubmit">
            {{ $t('main.save')  }}
            </span>
            <spinner-loading v-else text="loading"></spinner-loading>
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import withdrawServices from '../services/withdraw'
import mainInfo from '@/mixins/mainInfo'
export default {
  components: { },
  mixins: [mainInfo],
  mounted () {
    core.index()
    console.log(this.storeActive)
  },
  data () {
    return {
      withdraw: {
        amount: 0
      },
      loadingSubmit: false
    }
  },
  methods: {
    onSubmit () {
      this.loadingSubmit = true
      withdrawServices.createWithdraw(this.withdraw).then(res => {
        core.showSnackbar(res.data.message)
        this.loadingSubmit = false
        this.$router.push({ name: 'withdraw' })
      })
    }
  }
}
</script>
